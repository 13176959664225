import React from "react";
import { ThemeSwitcher } from "components";

const Settings: React.FC = () => {
  return (
    <>
      <ThemeSwitcher />
    </>
  );
};

export default Settings;
